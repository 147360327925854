import {Component, OnInit} from '@angular/core';
import {
  BookingStatus, BookingTypeStatus, DeleteStatus,
  exportAsExcelFile,
  formatFilter, formatFilterBooking, getFilterStatus,
  ItemMode,
  ListStatus,
  MasterDataStatus,
  SharedService
} from '../shared/shared.service';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {forkJoin, map} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {BookingItemComponent} from './booking-item/booking-item.component';
import moment from 'moment';
import {RefundModalComponent} from './refund-modal/refund-modal.component';
import {DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {duration} from 'moment/moment';
import {ActivatedRoute, Router} from '@angular/router';
import {Package} from '../shared/models/package.model';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.scss'
})
export class BookingComponent implements OnInit {
  listStatus = [
    {text: 'status.booked', value: BookingStatus.Booked, class: 'booked'},
    {text: 'status.in-house', value: BookingStatus.InHouse, class: 'active'},
    {text: 'status.canceled', value: BookingStatus.Canceled, class: 'canceled'},
    {text: 'status.back-early', value: BookingStatus.BackEarly, class: 'inactive'},
    {text: 'status.finished', value: BookingStatus.Finished, class: 'finished'}
  ];
  listBookingType = [
    {text: 'booking.stay', value: BookingTypeStatus.FOR_STAY},
    {text: 'booking.sell', value: BookingTypeStatus.FOR_SELL},
  ]
  tableConfig: any[] = [];
  filterConfig: any[] = [];
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-booking_no',
    code: null,
    booking_date: null,
    apartment: null,
    operate: null,
    floor: null,
    building: null,
    project: null,
    status: []
  };
  source: any[] = [];
  listBuilding: any[] = [];
  listPackage: any[] = [];
  listApartment: any[] = [];
  listOwner: any[] = [];
  totalRows: number = 0;
  dataTopUp!: any;

  constructor(
    private service: ProxyDynamicService,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation()) {
      this.dataTopUp = this.router.getCurrentNavigation()?.extras?.state?.['dataTopUp'] || null;
    }
  }

  ngOnInit() {
    this.getMasterData();
  }

  getMasterData() {
    const listAPI = [
      this.service.search('apartment', {fields: ['*.*'], filter: {deleted: {'_eq': DeleteStatus.NO}}}), this.service.search('package', {fields: ['*.*'], filter: {deleted: {'_eq': DeleteStatus.NO}}}),
      this.service.search('building', {filter: {deleted: {'_eq': DeleteStatus.NO}}}), this.service.search('owner', {filter: {deleted: {'_eq': DeleteStatus.NO}}})]
    forkJoin(listAPI).pipe(map(([listApartment, listPackage, listBuilding, listOwner]) => ({
      listApartment: listApartment.map((item: any) => {
        item['text'] = item.code;
        item['value'] = item.id;
        return item;
      }),
      listPackage: listPackage.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      }),
      listBuilding: listBuilding.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      }),
      listOwner: listOwner.map((item: any) => {
        item['text'] = item.name;
        item['value'] = item.id;
        return item;
      })
    }))).subscribe(e => {
      this.tableConfig = [
        {name: 'booking_no', title: 'booking.code', default: null, type: 'textAsLink', sortBy: 'ASC', width: '150px'},
        {name: 'booking_source', title: 'booking.date', default: null, type: 'text', sortBy: 'ASC', width: '150px', sortName: 'booking_date'},
        {name: 'apartment', title: 'apartment.label', default: null, type: 'object', sortBy: 'ASC', options: e.listApartment, width: '100px'},
        {name: 'package_id', title: 'service-package.label', default: null, type: 'dropdown', sortBy: 'ASC', options: e.listPackage, width: '200px'},
        {name: 'booking_type', title: 'booking.stay-reason', default: null, type: 'dropdown', sortBy: 'ASC', options: this.listBookingType, width: '160px'},
        {name1: 'from_date', name2: 'to_date', title: 'booking.stay-date', default: null, type: 'date-range', sortBy: 'ASC', width: '250px', sortName: 'from_date'},
        {name: 'guest_name', title: 'booking.main-guest', default: null, type: 'text', sortBy: 'ASC', width: '150px'},
        {name: 'card_count', title: 'booking.quantity', default: null, type: 'number', sortBy: 'ASC', width: '150px'},
        {name: 'adults_count', title: 'booking.adult', default: null, type: 'number', sortBy: 'ASC', width: '170px'},
        {name: 'child_count', title: 'booking.child', default: null, type: 'number', sortBy: 'ASC', width: '150px'},
        {name: 'night_count', title: 'booking.night', default: null, type: 'number', sortBy: 'ASC', width: '170px'},
        {name: 'status', title: 'status.title', default: null, type: 'status', col: 12, options: this.listStatus, nzRight: true, required: true, sortBy: 'ASC', width: '150px'}
      ];
      this.filterConfig = [
        {name: 'booking_no', title: 'booking.code', default: '', type: 'text', col: 6},
        {name: 'booking_date', title: 'booking.date', default: null, type: 'date', col: 6},
        {name: 'apartment', title: 'apartment.label', default: null, type: 'dropdownServer', dbName: 'apartment', col: 6, options: e.listApartment},
        {name: 'guest_name', title: 'booking.main-guest', default: '', type: 'text', col: 6},
        {name: 'from_date', title: 'booking.arrival', default: '', type: 'date', col: 6},
        {name: 'to_date', title: 'booking.depart', default: '', type: 'date', col: 6},
        {name: 'status', title: 'status.title', default: [], type: 'status-multi-select', col: 6, options: this.listStatus},
      ];
      this.listApartment = e.listApartment;
      this.listPackage = e.listPackage;
      this.listBuilding = e.listBuilding;
      this.listOwner = e.listOwner;
      this.getData();
      if (this.dataTopUp) {
        this.add();
      }
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    this.service.searchWithMeta('booking', {
      fields: ['*.*', 'created_by.*', 'updated_by.*', 'apartment.apartment_type.*'],
      filter: formatFilterBooking(this.filter, this.filterConfig),
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map(item => {
        item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
        item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
        item['booking_source'] = this.datePipe.transform(item['booking_date'], 'dd/MM/yyyy') + (item.source === 'KIOSK' ? ' - ' + 'KIOSK' : '');
        item['status'] = getFilterStatus(item);
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.Edit,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '', this.translate.instant('booking.updateSuccess'));
        this.getData();
      }
    });
  }

  delete($event: any) {
    this.service.delete('booking', $event.id).then(res => {
      this.noti.create('success', '', this.translate.instant('booking.deleteSuccess'));
      this.getData();
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  view($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.View,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('booking.updateSuccess'));
        this.getData();
      }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: BookingItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-booking',
      nzData: {
        mode: ItemMode.Add,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listBuilding: this.listBuilding.filter(r => r.status === MasterDataStatus.Active),
        listStatus: this.listStatus,
        dataTopUp: this.dataTopUp || null
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('booking.addSuccess'));
        this.getData();
      }
      this.dataTopUp = null;
      window.history.replaceState({}, "");
    });
  }

  onRefund($event: any) {
    let night = 0;
    if ($event.status === BookingStatus.Booked) {
      night = $event.night_count
    }
    if ($event.status === BookingStatus.InHouse) {
      night = moment($event.to_date).diff(moment(), 'days');
    }
    const modalRef = this.modalService.create({
      nzContent: RefundModalComponent,
      nzWidth: '400px',
      nzClassName: 'modal-booking',
      nzData: {
        data: $event,
        night: night
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  onCancelStay($event: any) {
    const obj = {
      night_count: 0,
      note: '',
      apartment_id: $event.apartment.id,
      booking_id: $event.id,
      status: BookingStatus.Canceled
    }
    this.service.createItem('booking/night-refund', obj).subscribe(() => {
      this.noti.create('success', this.translate.instant('cancelSuccess'), '');
      this.getData();
    });
  }

  export() {
    this.service.search('booking', {
      "limit": this.totalRows,
      "page": 0,
      'sort': [this.filter['sort']],
      fields: ['*.*', 'created_by.*', 'updated_by.*'],
      filter: formatFilterBooking(this.filter, this.filterConfig),
    }).then(dataResult => {
      let configColumn = [
        {field: 'booking_no', headerText: 'booking.code'},
        {field: 'booking_source', headerText: 'booking.date'},
        {field: 'apartment_name', headerText: 'apartment.label'},
        {field: 'booking_type', headerText: 'booking.stay-reason'},
        {field: 'from_to_date', headerText: 'booking.stay-date'},
        {field: 'guest_name', headerText: 'booking.main-guest'},
        {field: 'card_count', headerText: 'booking.quantity'},
        {field: 'adults_count', headerText: 'booking.adult'},
        {field: 'child_count', headerText: 'booking.child'},
        {field: 'night_count', headerText: 'booking.night'},
        {field: 'status', headerText: 'status.title'}
      ];
      let listExport: any[] = [];
      if (dataResult?.length > 0) {
        dataResult.map((item: any) => {
          item['night_count'] = Math.round(duration(moment(item['to_date']).diff(moment(item['from_date']))).asDays());
          item['apartment_name'] = item['apartment'] ? item['apartment'].code : undefined;
          item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
          item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
          item['booking_source'] = this.datePipe.transform(item['booking_date'], 'dd/MM/yyyy') + (item.source === 'KIOSK' ? ' - ' + 'KIOSK' : '');
          item['from_to_date'] = this.datePipe.transform(item['from_date'], 'dd/MM/yyyy HH:mm:ss') + ' - ' + this.datePipe.transform(item['to_date'], 'dd/MM/yyyy HH:mm:ss');
          item['status'] = getFilterStatus(item);
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = typeof data[e.field] === "number" ? data[e.field].toString() : (data[e.field] || '');
            if (e.field === 'status' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listStatus.find(r => r.value === data[e.field])?.text  || '')
            }
            if (data[e.field] && e.field === 'booking_type') {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listBookingType.find(r => r.value === data[e.field])?.text  || '')
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('booking.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.tableConfig.forEach((col) => {
      if ((col['sortName'] && col['sortName'] === event) || (!col['sortName'] && col['name'] === event)) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {ListComponent as ProjectListComponent} from './master-data/projects/list/list.component';
import {ListComponent as BuildingListComponent} from './master-data/building/list/list.component';
import {ListComponent as FloorListComponent} from './master-data/floor/list/list.component';
import {ListComponent as OwnerListComponent} from './master-data/owner/list/list.component';
import {ListComponent as ApartmentTypeListComponent} from './master-data/apartment-type/list/list.component';
import {ListComponent as ApartmentListComponent} from './master-data/apartment/list/list.component';
import {ListComponent as ServiceListComponent} from './master-data/service/list/list.component';
import {ListComponent as PackageServiceComponent} from './master-data/package-service/list/list.component';
import {AuthGuard} from './shared/directive/authentication.guard';
import {BookingComponent} from './booking/booking.component';
import {TopUpComponent} from './topup/top-up.component';
import {BillingComponent} from './billing/billing.component';
import {PublicCardComponent} from './public-card/public-card.component';
import {ChangePasswordComponent} from './change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/top-up',
    pathMatch: 'full'
  },
  {
    path: 'projects', loadChildren: () => import('./master-data/projects/projects.module')
    .then((m) => m.ProjectsModule),
    component: ProjectListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'apartment', loadChildren: () => import('./master-data/apartment/apartment.module')
    .then((m) => m.ApartmentModule),
    component: ApartmentListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'building', loadChildren: () => import('./master-data/building/building.module')
    .then((m) => m.BuildingModule),
    component: BuildingListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'floor', loadChildren: () => import('./master-data/floor/floor.module')
    .then((m) => m.FloorModule),
    component: FloorListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'owner', loadChildren: () => import('./master-data/owner/owner.module')
    .then((m) => m.OwnerModule),
    component: OwnerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'apartment-type', loadChildren: () => import('./master-data/apartment-type/apartment-type.module')
    .then((m) => m.ApartmentTypeModule),
    component: ApartmentTypeListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'card', loadChildren: () => import('./public-card/public-card.module')
    .then((m) => m.PublicCardModule),
    component: PublicCardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service', loadChildren: () => import('./master-data/service/service.module')
    .then((m) => m.ServiceModule),
    component: ServiceListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'service-package', loadChildren: () => import('./master-data/package-service/package-service.module')
    .then((m) => m.PackageServiceModule),
    component: PackageServiceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'booking', loadChildren: () => import('./booking/booking.module')
    .then((m) => m.BookingModule),
    component: BookingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'top-up', loadChildren: () => import('./topup/top-up.module')
    .then((m) => m.TopUpModule),
    component: TopUpComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'billing', loadChildren: () => import('./billing/billing.module')
    .then((m) => m.BillingModule),
    component: BillingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password', loadChildren: () => import('./change-password/change-password.module')
    .then((m) => m.ChangePasswordModule),
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'public-card', loadChildren: () => import('./public-card/public-card.module')
  //   .then((m) => m.PublicCardModule),
  //   component: PublicCardComponent,
  //   canActivate: [AuthGuard]
  // },
  {path: 'login', loadChildren: () => import('./login/login.module')
    .then((m) => m.LoginModule)
  },
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

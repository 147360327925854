import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ProxyDynamicService} from './shared/service/proxy-dynamic.service';
import moment from 'moment/moment';
import {TokenStorageService} from './shared/service/token.service';
import {TranslateService} from '@ngx-translate/core';
import {IdentificationReaderService} from './shared/service/identificationReader.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {take} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {SocketIoService} from './shared/service/socket-io.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showLogin = false;
  socketConnected = false;

  constructor(
    private router: Router,
    private service: ProxyDynamicService,
    private translateService: TranslateService,
    private identificationReaderService: IdentificationReaderService,
    private noti: NzNotificationService,
    private socket: SocketIoService,
    private config: PrimeNGConfig
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showLogin = event.url.includes('login');
        this.socket.reconnect();
      }
    });

    this.config.setTranslation({
      dayNamesMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
      monthNames: ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'],
      monthNamesShort: ['Thg 1', 'Thg 2', 'Thg 3', 'Thg 4', 'Thg 5', 'Thg 6', 'Thg 7', 'Thg 8', 'Thg 9', 'Thg 10', 'Thg 11', 'Thg 12']
    })
    // console.log(this.translateService.getBrowserCultureLang())
    // const lang = this.translateService.getBrowserCultureLang() || 'vi';
    this.translateService.setDefaultLang('vi');
    if (!this.showLogin) {
      this.identificationReaderService.initialize();
      this.checkIdReaderConnected();
    }
  }

  checkIdReaderConnected() {
    this.identificationReaderService.getIdReaderConnectedState().pipe(take(1)).subscribe((connected: boolean) => {
      this.socketConnected = connected;
      this.noti.create(connected ? 'success' : 'error', '', this.translateService.instant(connected ? 'connected-success' : 'connected-fail'));
    });
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {
  DeleteStatus,
  exportAsExcelFile,
  GuestType,
  ItemMode,
  MasterDataStatus,
  PaymentMethod,
  SharedService
} from '../shared/shared.service';
import {forkJoin, map} from 'rxjs';
import {TopupItemComponent} from './topup-item/topup-item.component';
import {DatePipe} from '@angular/common';
import {BillingItemComponent} from '../billing/billing-item/billing-item.component';
import {FormPrintComponent} from './form-print/form-print.component';
import moment from 'moment';

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrl: './top-up.component.scss'
})
export class TopUpComponent implements OnInit {
  listGuestType = [
    {text: 'owner.label', value: GuestType.Owner},
    {text: 'guest', value: GuestType.Guest},
  ]
  tableConfig: any[] = [];
  filterConfig: any[] = [];
  filter: any = {
    limit: 15,
    page: 1,
    sort: '-ticket_no',
    deposit_date: null,
    ticket_no: null,
    package_id: null,
    guest_type: null,
    owner_id: null,
    apartment_codes: null
  };
  source: any[] = [];
  listApartment: any[] = [];
  listPackage: any[] = [];
  listOwner: any[] = [];
  totalRows: number = 0;
  @ViewChild('formPrintComponent') formPrintComponent!: FormPrintComponent;

  constructor(
    private service: ProxyDynamicService,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getMasterData();
  }

  getMasterData() {
    this.sharedService.showLoading(true);
    const listAPI = [this.service.search('apartment', {filter: {deleted: {'_eq': DeleteStatus.NO}}, sort: '-code'}),
      this.service.search('owner', {filter: {deleted: {'_eq': DeleteStatus.NO}}, sort: '-code'}),
      this.service.search('package', {fields: ['*.*'], filter: {deleted: {'_eq': DeleteStatus.NO}}, sort: '-code'})]
    forkJoin(listAPI).pipe(map(([listApartment, listOwner, listPackage]) => ({
      listApartment: listApartment.map((item: any) => {
        item['text'] = item.code;
        item['value'] = item.id;
        return item;
      }),
      listOwner: listOwner.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      }),
      listPackage: listPackage.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      })
    }))).subscribe(e => {
      this.listApartment = e.listApartment;
      this.listPackage = e.listPackage;
      this.listOwner = e.listOwner;
      this.tableConfig = [
        {name: 'ticket_no', title: 'top-up.code', default: null, type: 'textAsLink', sortBy: 'DESC'},
        {name: 'deposit_date', title: 'top-up.date', default: null, type: 'date', sortBy: 'ASC'},
        {name: 'package_name', title: 'service-package.label', default: null, type: 'text', options: e.listPackage, sortBy: 'ASC'},
        {name: 'guest_type', title: 'top-up.guest-type', default: null, type: 'dropdown', options: this.listGuestType, sortBy: 'ASC'},
        {name: 'owner_names', title: 'owner.code', default: null, type: 'text', options: e.listOwner, sortBy: 'ASC', width: '300px'},
        {name: 'apartment_codes', title: 'apartment.code', default: null, type: 'text', sortBy: 'ASC'},
        {name: 'payment_amount', title: 'top-up.total', default: null, type: 'number', sortBy: 'ASC'},
        {name: 'paid_amount', title: 'top-up.paid', default: null, type: 'number', sortBy: 'ASC'},
        // {name: 'due_amount', title: 'top-up.left', default: null, type: 'number', sortBy: 'ASC'},
      ];
      this.filterConfig = [
        {name: 'ticket_no', title: 'top-up.code', default: '', type: 'text', col: 8},
        {name: 'deposit_date', title: 'top-up.date', default: null, type: 'date', col: 8},
        {name: 'package_id', title: 'service-package.label', default: null, type: 'dropdown', col: 8, options: e.listPackage},
        {name: 'owner_id', title: 'owner.code', default: '', type: 'dropdownServer', col: 8, dbName: 'owner'},
        {name: 'apartment_codes', title: 'apartment.code', default: '', type: 'dropdownServer', col: 8, dbName: 'apartment', filterBy: 'code'},
        {name: 'guest_type', title: 'top-up.guest-type', default: null, type: 'dropdown', col: 6, options: this.listGuestType},
        {name: 'deleted', label: 'deleted', default: '', type: 'checkbox', col: 2},
      ];
      this.sharedService.showLoading(false);
      this.getData();
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        if (key === 'page') {
          this.filter[key] = event[key] + 1;
        } else {
          this.filter[key] = event[key];
        }
      })
    }
    this.service.getListTopup({
      page: this.filter.page,
      limit: this.filter.limit,
      sortOrder: this.filter.sort.includes('-') ? 'DESC' : 'ASC',
      sortField: this.filter.sort.includes('-') ? this.filter.sort.replace('-', '') : this.filter.sort,
      searchFields: this.filterConfig.map((item: any) => {
        if (this.filter[item.name]) {
          let obj = {
            field: item.name,
            operator: item.name === 'apartment_codes' || item.name === 'owner_ids' ? 'in' : '=',
            value: item.type === 'date' ? moment(this.filter[item.name]).format('YYYY-MM-DD') :
              (item.type === 'checkbox' && this.filter[item.name] ? DeleteStatus.YES : this.filter[item.name]),
          }
          return obj;
        }
        return;
      }).filter(r => r)
    }).subscribe((res: any) => {
      this.totalRows = res.pagination.totalRecords;
      this.source = res?.data.map((item: any) => {
        item['deleted'] = this.filter['deleted'] ? DeleteStatus.YES : DeleteStatus.NO;
        return item;
      });
      this.sharedService.showLoading(false);
    }, (error: any) => {
     this.service.handleError(error);
    });
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit($event: any) {
    const modalRef = this.modalService.create({
      nzContent: TopupItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-top-up',
      nzData: {
        mode: ItemMode.Edit,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPackage: this.listPackage.filter(r => r.status === MasterDataStatus.Active),
        listGuestType: this.listGuestType,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('top-up.updateSuccess'));
        this.getData();
      }
    });
  }
  delete($event: any) {
    this.service.deleteItemTopUp($event.id).subscribe(res => {
      this.noti.create('success', '',
        this.translate.instant('top-up.deleteSuccess'));
      this.getData();
    }, (error: any) => {
      this.service.handleError(error)
    });
  }

  view($event: any) {
    const modalRef = this.modalService.create({
      nzContent: TopupItemComponent,
      nzWidth: '60vw',
      nzClassName: 'modal-top-up',
      nzData: {
        mode: ItemMode.View,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPackage: this.listPackage.filter(r => r.status === MasterDataStatus.Active),
        listGuestType: this.listGuestType,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        if (result.print) {
          this.onPrint(result.print);
        } else {
          this.noti.create('success', '',
            this.translate.instant('top-up.updateSuccess'));
        }
      }
      this.getData();
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: TopupItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-top-up',
      nzData: {
        mode: ItemMode.Add,
        listApartment: this.listApartment.filter(r => r.status === MasterDataStatus.Active),
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPackage: this.listPackage.filter(r => r.status === MasterDataStatus.Active),
        listGuestType: this.listGuestType,
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('top-up.addSuccess'));
        this.getData();
      }
    });
  }

  export() {
    this.sharedService.showLoading(true);
    this.service.getListTopup({
      sortOrder: this.filter.sort.includes('-') ? 'DESC' : 'ASC',
      sortField: this.filter.sort.includes('-') ? this.filter.sort.replace('-', '') : this.filter.sort,
      searchFields: this.filterConfig.map((item: any) => {
        if (this.filter[item.name]) {
          let obj = {
            field: item.name,
            operator: item.name === 'apartment_codes' || item.name === 'owner_ids' ? 'in' : '=',
            value: item.type === 'date' ? moment(this.filter[item.name]).format('YYYY-MM-DD') :
              (item.type === 'checkbox' && this.filter[item.name] ? DeleteStatus.YES : this.filter[item.name]),
          }
          return obj;
        }
        return;
      }).filter(r => r)
    }).subscribe((dataResult: any) => {
      let configColumn = this.tableConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if(dataResult?.data?.length > 0) {
        dataResult.data.forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = typeof data[e.field] === "number" ? data[e.field].toString() : (data[e.field] || '');
            if ((e.field === 'date_created' || e.field === 'date_updated') && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'dd/MM/yyyy HH:mm:ss');
            }
            if (e.field === 'deposit_date' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'dd/MM/yyyy');
            }
            if (e.field === 'guest_type' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listGuestType.find(r => r.value === data[e.field])?.text || '');
            }
            if ((e.field === 'owner' || e.field === 'package_id') && data[e.field]) {
              item[this.translate.instant(e.headerText)] = data[e.field].code + ' - ' + data[e.field].name;
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('top-up.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
      this.sharedService.showLoading(false);
    }, (error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.tableConfig.forEach((col) => {
      if (col['name'] === event) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }

  billing(event: any) {
    const modalRef = this.modalService.create({
      nzContent: BillingItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-billing',
      nzData: {
        mode: ItemMode.Add,
        listPaymentMethod: [
          {text: 'cash', value: PaymentMethod.Cash},
          {text: 'transfer', value: PaymentMethod.Transfer},
        ],
        listOwner: this.listOwner,
        dataTopUp: event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('addSuccess', {value: (this.translate.instant('billing.title'))}));
        this.getData();
      }
    });
  }

  onPrint(event: any) {
    this.sharedService.showLoading(true);
    this.formPrintComponent.data = event;
    this.formPrintComponent.getTopUpDetail();
    setTimeout(() => {
      this.sharedService.showLoading(false);
      setTimeout(() => {
        window.print()
      }, 500);
    }, 1000);
  }
}

<div class="bg-white p-3" style="border-radius: 4px">
  <form [formGroup]="formGroup" *ngIf="mode !== itemMode.View">
    <h4>{{ 'booking.booking-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzSpan="24">
        <app-control formControlName="booking_date" [max]="f['fromTo'].value[0]"
                     [controlConfig]="{title: 'booking.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="building" [options]="listBuilding"
                     [controlConfig]="{title: 'building.label', type: 'dropdown', required: true, options: listBuilding}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="apartment" [options]="listApartment" (getMoreData)="getMoreDataApartment($event)" (dropdownSearch)="filterDataApartment($event)"
                     [controlConfig]="{title: 'apartment.label', type: 'dropdownServer', required: true, options: listApartment}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="ownerName" [controlConfig]="{title: 'owner.label', nonPlaceholder: true, type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="owner_remaining_nights"
                     [controlConfig]="{title: 'booking.owner-remain', nonPlaceholder: true, type: 'text-number'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="guest_remaining_nights"
                     [controlConfig]="{title: 'booking.guest-remain', nonPlaceholder: true, type: 'text-number'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="package_id" [options]="listPackage"
                     [controlConfig]="{title: 'service-package.label', nonPlaceholder: true, required: true, type: 'dropdown'}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="adults_count"
                     [controlConfig]="{title: 'booking.adult', type: 'number', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="6">
        <app-control formControlName="child_count"
                     [controlConfig]="{title: 'booking.child', type: 'number'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="fromTo" [min]="f['booking_date'].value ? f['booking_date'].value : currentDateMin"
                     [controlConfig]="{title: 'booking.stay-date', type: 'datetime-range', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="night_count"
                     [controlConfig]="{title: 'booking.night', nonPlaceholder: true, type: 'text-number'}"></app-control>
      </div>
    </div>
    <h4 class="mt-3">{{ 'booking.main-guest-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzSpan="12">
        <app-control formControlName="guest_name"
                     [controlConfig]="{title: 'booking.guest-name', max: 255, type: 'text', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="guest_phone"
                     [controlConfig]="{title: 'phone', type: 'text-number', max: 12}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="gender" [options]="listGender"
                     [controlConfig]="{title: 'gender.title', type: 'radio-group', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="birthday" [max]="currentDateMax"
                     [controlConfig]="{title: 'birthday', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="doc_type" [options]="listDocType"
                     [controlConfig]="{title: 'doc.type', type: 'dropdown', required: true, options: listDocType}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="doc_no"
                     [controlConfig]="{title: 'doc.code', type: 'text', required: true, regex: REGEX.code}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="issue_date" [max]="f['expired_date'].value"
                     [controlConfig]="{title: 'doc.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="issue_place" [controlConfig]="{title: 'doc.place', type: 'text', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="expired_date" [min]="f['issue_date'].value"
                     [controlConfig]="{title: 'doc.expire', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="address"
                     [controlConfig]="{title: 'address', type: 'text', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <label><span>{{ 'booking.img' | translate }}</span></label>
        <div class="d-flex gap-2 mb-1 ">
          <image-upload [imageSrc]="f['doc_img_front'].value" [label]="'booking.img-front'" [idImg]="'front'"
                        (onUpload)="handleFileUpload('doc_img_front', $event)" [size]="'small'"></image-upload>
          <image-upload [imageSrc]="f['doc_img_back'].value" [label]="'booking.img-back'" [idImg]="'back'"
                        (onUpload)="handleFileUpload('doc_img_back', $event)" [size]="'small'"></image-upload>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="mode === itemMode.View">
    <div class="table-info-box mb-3">
      <div class="box-title">
        <h4>{{ 'booking.booking-info' | translate }}</h4>
      </div>
      <div class="pt-3 px-3" nz-row [nzGutter]="[16, 16]">
        <div nz-col nzSpan="6">
          <div>{{ 'booking.code' | translate }}</div>
          <div class="content">{{ data.booking_no }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.date' | translate }}</div>
          <div class="content">{{ data.booking_date }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.source' | translate }}</div>
          <div class="content">{{ data.source }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'status.title' | translate }}</div>
          <div class="content">
            <ng-container *ngFor="let item of listStatus">
              <span class="status-box" [ngClass]="item.class" *ngIf="item.value === data.status">{{ item.text | translate }}</span>
            </ng-container>
          </div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'building.label' | translate }}</div>
          <div class="content">{{ data.building ? data.building.name : '' }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'apartment.label' | translate }}</div>
          <div class="content">{{ data.apartment ? (data.apartment.code + ' ' +
            data.apartment.apartment_type.code + '-' + data.apartment.apartment_type.name) : '' }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'owner.label' | translate }}</div>
          <div class="content">{{ data.owner ? data.owner.name : '' }}</div>
        </div>
        <div nz-col nzSpan="4">
          <div>{{ 'service-package.label' | translate }}</div>
          <div class="content">{{ data.package_id ? (data.package_id.code + ' - ' + data.package_id.name) : ''}}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.stay-date' | translate }}</div>
          <div class="content">{{ data.from_date | date: 'dd/MM/yyyy HH:mm' }} - {{ data.to_date | date: 'dd/MM/yyyy HH:mm' }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.night' | translate }}</div>
          <div class="content">{{ data.night_count }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.quantity' | translate }}</div>
          <div class="content">{{ data.card_count }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'booking.adult' | translate }} - {{ 'booking.child' | translate }}</div>
          <div class="content">{{ data.adults_count }} - {{ data.child_count }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'created' | translate }}</div>
          <div class="content">{{ data.created_at | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'creator' | translate }}</div>
          <div class="content">{{ data.creator }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'updated' | translate }}</div>
          <div class="content">{{ data.updated_at | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
        </div>
        <div nz-col nzSpan="6">
          <div>{{ 'updater' | translate }}</div>
          <div class="content">{{ data.updater }}</div>
        </div>
      </div>
    </div>
    <div class="table-info-box">
      <div class="box-title">
        <h4 class="mt-3">{{ 'booking.main-guest-info' | translate }}</h4>
      </div>
      <div class="p-3 pb-0" nz-row [nzGutter]="[16, 16]">
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'booking.guest-name' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.guest_name }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'phone' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.guest_phone }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'doc.type' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              <ng-container *ngFor="let item of listDocType">
                <span *ngIf="item.value === data.doc_type">{{ item.text | translate }}</span>
              </ng-container>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'doc.code' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.doc_no }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'birthday' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.birthday | date: 'dd/MM/yyyy' }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'gender.title' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              <ng-container *ngFor="let item of listGender">
                <span *ngIf="item.value === data.gender">{{ item.text | translate }}</span>
              </ng-container>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'doc.date' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.issue_date | date: 'dd/MM/yyyy' }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'doc.place' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.issue_place }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'address' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.address }}
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <div nz-row [nzGutter]="[16, 16]">
            <div nz-col nzSpan="12">
              {{ 'doc.expire' | translate }}
            </div>
            <div class="content" nz-col nzSpan="12">
              {{ data.expired_date | date: 'dd/MM/yyyy' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-white p-3 mt-2" style="border-radius: 4px" *ngIf="mode !== itemMode.View || (mode === itemMode.View && listDataShareGuest.length)">
  <div class="table-info-box">
    <div class="box-title w-100 d-flex justify-content-between">
      <h4 class="mb-0">{{ 'booking.share-guest-info' | translate }}</h4>
      <div>
        <lucide-icon [name]="expand ? 'chevron-up' : 'chevron-down'" (click)="expand = !expand"></lucide-icon>
      </div>
    </div>
    <div *ngIf="expand" class="p-4">
      <nz-table #table [nzData]="listDataShareGuest" [nzScroll]="listDataShareGuest.length > 5 ? {y: '10vh'} : {}"
                nzTableLayout="auto" [nzBordered]="true" nzSize="small"
                [nzShowPagination]="false">
        <thead>
        <tr>
          <th style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'booking.guest-name' | translate }}</th>
          <th>{{ 'phone' | translate }}</th>
          <th>{{ 'doc.code' | translate }}</th>
          <th>{{ 'birthday' | translate }}</th>
          <th>{{ 'gender.title' | translate }}</th>
          <th style="width: 100px" *ngIf="mode !== itemMode.View"></th>
        </tr>
        </thead>
        <ng-container *ngIf="listDataShareGuest.length">
          <tbody>
          <tr *ngFor="let item of listDataShareGuest; let i = index">
            <td class="text-center" style="background-color: #F9FAFB; width: 60px;">{{ i + 1 }}</td>
            <td>
              <a href="javascript:void(0)" class="textAsLink" nz-tooltip [nzTooltipTitle]="item.full_name"
                 (click)="view(i)">{{ item.full_name }}</a></td>
            <td>{{ item.phone }}</td>
            <td>{{ item.doc_no }}</td>
            <td>{{ item.birthday | date: 'dd/MM/yyyy' }}</td>
            <td>
              <ng-container *ngFor="let gender of listGender">
                <span *ngIf="gender.value === item.gender">{{ gender.text | translate}}</span>
              </ng-container>
            </td>
            <td *ngIf="mode !== itemMode.View">
              <app-button [type]="'icon'" [label]="'pencil-line'" (clickButton)="edit(i)"></app-button>
              <app-button [type]="'icon'" [label]="'trash-2'" (clickButton)="delete(i)"></app-button>
            </td>
          </tr>
          </tbody>
        </ng-container>
      </nz-table>

      <ng-container *ngIf="!listDataShareGuest.length">
        <div style="width: 100%; text-align: center">
          <ord-grid-not-data></ord-grid-not-data>
        </div>
      </ng-container>
      <app-button *ngIf="mode !== itemMode.View" class="mt-3 d-block" (clickButton)="add()" [type]="'icon-outline'" [label]="'plus'"></app-button>
    </div>
  </div>
</div>
<div *nzModalTitle class="title-modal">{{ mode |translate }} {{ 'booking.label' | translate }}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" *ngIf="!nzModalData.notAllowEdit" (click)="editMode()">{{ 'edit' | translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode !== itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>
  </ng-container>
</div>

import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SharedService} from '../shared/shared.service';
import {authentication, createDirectus} from '@directus/sdk';
import {environment} from '../../environments/environment';
import {TokenStorageService} from '../shared/service/token.service';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';

@Component({
  selector: 'app-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements AfterViewInit{
  isCollapsed: boolean = false;
  currentPath!: string;
  isLoading: boolean = false;
  sideMenuConfig = [
    {title: 'top-up.title', path: '/top-up', selected: false, icon: 'moon-star'},
    {title: 'billing.title', path: '/billing', selected: false, icon: 'receipt-text'},
    {title: 'booking.title', path: '/booking', selected: false, icon: 'tickets'},
    // {title: 'public-card.title', path: '/public-card', selected: false, icon: 'credit-card'},
    // {title: 'history.title', path: '/history', selected: false, icon: 'history'},
    {title: 'master-data', path: '', selected: false, icon: 'table-properties', children: [
        {title: 'project.title', path: '/projects', selected: false, icon: ''},
        {title: 'building.title', path: '/building', selected: false, icon: ''},
        {title: 'floor.title', path: '/floor', selected: false, icon: ''},
        {title: 'owner.title', path: '/owner', selected: false, icon: ''},
        {title: 'apartment-type.title', path: '/apartment-type', selected: false, icon: ''},
        {title: 'apartment.title', path: '/apartment', selected: false, icon: ''},
        {title: 'card.title', path: '/card', selected: false, icon: ''},
        {title: 'service.title', path: '/service', selected: false, icon: ''},
        {title: 'service-package.title', path: '/service-package', selected: false, icon: ''},
      ]},
  ]
  username: string = '';
  visible: boolean = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private sharedService: SharedService,
    private tokenService: TokenStorageService,
    private service: ProxyDynamicService,
  ) {
    this.sharedService.showLoadingObservable.subscribe((res) => {
      this.isLoading = res;
    });
    this.service.getUser().then((user) => {
      console.log(user)
      this.username = user['first_name'] + ' ' + user['last_name'];
    })
  }
  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.urlAfterRedirects;
        if (this.currentPath && this.currentPath !== '/') {
          this.setSelectedMenuItem(this.sideMenuConfig);
        }
        this.cdr.detectChanges();
      }
    });
  }

  setSelectedMenuItem(list: Array<any>) {
    for (let item of list) {
      if (item.children && item.children.length > 0) {
        this.setSelectedMenuItem(item.children);
        item.selected = item.children.findIndex((r: any) => r.selected) !== -1;
      } else {
        if (item.path && new RegExp('^' + item.path + '$').test(this.currentPath)) {
          item.selected = true;
        } else if (item.childPath && item.childPath.length) {
          for (let path of item.childPath) {
            if (path) {
              item.selected = new RegExp(path).test(this.currentPath);
            }
            if (item.selected) {
              break;
            }
          }
        } else {
          item.selected = false;
        }
      }
    }
  }

  logout() {
    this.service.signOut().then(() => {
      this.tokenService.signOut();
    });
  }

  changePass() {
    this.visible = false;
    return this.router.navigate(['/change-password']);
  }
}

<div id="printContent" class="print" style="width: 100%">
  <div class="d-flex justify-content-between print">
    <div style="width: 70%" class="fs-14">
      <div class="label">{{ 'print.company' | translate }}<span>Công ty CP Quản lý Khách sạn & Dịch vụ Mandala - CN Mũi Né</span>
      </div>
      <div class="label">{{ 'print.company-address' | translate }}<span>Khu du lịch nghỉ dưỡng Apec Mandala Wyndham Mũi Né
        P.Mũi Né - TP Phan Thiết - Tỉnh Bình Thuận</span>
      </div>
      <div class="label">{{ 'print.company-tax' | translate }}<span>0108129263 -005</span></div>
    </div>
    <div style="width: fit-content;" class="fs-14">
      <div class="label">{{ 'print.ticket' | translate }}{{ data?.ticket_no }}</div>
      <div class="label">{{ 'print.topup-date' | translate }}{{ data?.deposit_date | date: 'dd/MM/yyyy' }}</div>
    </div>
  </div>
  <h4 class="text-center" style="width: 100%; font-size: 24px; font-weight: 600; margin: 6px auto 16px auto">{{ 'print.topup-title' | translate }}</h4>
  <div class="d-flex justify-content-between">
    <div style="width: 50%">
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.buyer' | translate }}:</div>
        <div>{{ payer }}</div>
      </div>
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.table.package' | translate }}:</div>
        <div>{{ data?.package_name }}</div>
      </div>
    </div>
    <div style="width: 50%">
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.guest-type' | translate }}:</div>
        <ng-container *ngFor="let item of listGuestType">
          <div *ngIf="item.value === data?.guest_type">{{ 'print.' + item.text | translate }}</div>
        </ng-container>
      </div>
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.note' | translate }}:</div>
        <div>{{ data?.note }}</div>
      </div>
    </div>
  </div>
  <div style="margin-bottom: 16px; font-weight: 600; margin-top: 16px;">{{ 'print.topup-detail' | translate }}</div>
  <div>
    <nz-table #table [nzData]="listPayment"
              nzTableLayout="auto" [nzBordered]="true" nzSize="small"
              [nzShowPagination]="false">
      <thead>
      <tr>
        <th class="text-center" style="width: 60px">{{ 'index' | translate }}</th>
        <th>{{ 'print.table.apartment' | translate }}</th>
        <th>{{ 'print.table.price' | translate }}</th>
        <th>{{ 'print.table.night' | translate }}</th>
        <th>{{ 'print.table.cost' | translate }}</th>
<!--        <th>{{ 'print.table.discount' | translate }}</th>-->
<!--        <th>{{ 'print.table.discount-cost' | translate }}</th>-->
<!--        <th>{{ 'print.table.after-cost' | translate }}</th>-->
      </tr>
      </thead>
      <ng-container *ngIf="listPayment.length">
        <tbody>
        <tr *ngFor="let item of listPayment; let i = index">
          <td class="text-center" style="background-color: #F9FAFB">{{ i + 1 }}</td>
          <td class="text-center">{{ item['name'] }}</td>
          <td style="text-align: right">{{ item['price'] | number }}</td>
          <td style="text-align: right">{{ item['night_count'] }}</td>
          <td style="text-align: right">{{ (item.total | number) || 0 }}</td>
<!--          <td>{{ item['discount'] | number }}{{ item['is_percent'] && item['discount'] ? '%' : '' }}</td>-->
<!--          <td style="text-align: right">{{ item['discount_cost'] | number }}</td>-->
<!--          <td style="text-align: right">{{ item['total_after_discount'] | number }}</td>-->
        </tr>
        <tr style="font-weight: 600;">
          <td class="text-center">{{ listPayment.length + 1 }}</td>
          <td class="text-center">{{ 'print.total' | translate }}</td>
          <td></td>
          <td style="text-align: right">{{ totalNight }}</td>
          <td style="text-align: right">{{ totalPayment | number }}</td>
<!--          <td></td>-->
<!--          <td style="text-align: right">{{ totalDiscount | number }}</td>-->
<!--          <td style="text-align: right">{{ totalAfterDiscount | number }}</td>-->
        </tr>
        </tbody>
      </ng-container>
    </nz-table>
  </div>
  <div style="margin-top: 32px; width: 100%; min-height: 150px">
    <div style="float: right; width: fit-content; min-width: 400px;">
<!--      <div class="fs-14 d-flex justify-content-between" style="margin-bottom: 6px; gap: 8px">-->
<!--        <div class="label">{{ 'payment.discount' | translate }}:</div>-->
<!--        <div>{{ totalDiscount ? (totalDiscount | number) : 0 }}</div>-->
<!--      </div>-->
<!--      <div class="fs-14 d-flex justify-content-between" style="margin-bottom: 6px; gap: 8px">-->
<!--        <div class="label">{{ 'payment.after-discount' | translate }}</div>-->
<!--        <div>{{ totalAfterDiscount ? (totalAfterDiscount | number) : 0 }}</div>-->
<!--      </div>-->
<!--      <div class="fs-14 d-flex justify-content-between" style="margin-bottom: 6px; gap: 8px">-->
<!--        <div class="label">{{ 'payment.vat' | translate: {percent: 10} }}</div>-->
<!--        <div>{{ totalVAT ? (totalVAT | number) : 0 }}</div>-->
<!--      </div>-->
      <div class="fs-14 d-flex justify-content-between" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'payment.total' | translate }}</div>
        <div>{{ totalPayment ? (totalPayment | number) : 0 }}</div>
      </div>
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.amount-by-text' | translate }} {{ totalPayment | textCurrency }}</div>
      </div>
    </div>
  </div>
  <div style="margin-bottom: 16px; font-weight: 600; margin-top: 16px;">{{ 'print.payment-info' | translate }}</div>
  <div style="margin-top: 32px">
    <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
      <div class="label">{{ PaymentMethod.Cash | translate }}:
        <span>{{ totalByCash ? (totalByCash | number) + ' (' + (totalByCash | textCurrency) + ')' : '' }}</span></div>
    </div>
    <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
      <div class="label">{{ PaymentMethod.Transfer | translate }}:
        <span>{{ totalByTransfer ? (totalByTransfer | number) + ' (' + (totalByTransfer | textCurrency) + ')' : '' }}</span>
      </div>
    </div>
  </div>
  <div style="margin-top: 32px" class="d-flex justify-content-between">
    <div style="width: 200px; text-align: center">
      <div style="margin-bottom: 60px; font-weight: 600">{{ 'print.bill-creator' | translate }}</div>
      <div>{{ data?.created_by }}</div>
    </div>
    <div style="width: 200px; text-align: center">
      <div style="margin-bottom: 60px; font-weight: 600">{{ 'print.buyer' | translate }}</div>
      <div>{{ payer }}</div>
    </div>
  </div>
</div>

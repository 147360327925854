<div id="printContent" class="print" style="width: 100%">
  <div class="d-flex justify-content-between print">
    <div style="width: 70%" class="fs-14">
      <div class="label">{{ 'print.company' | translate }}<span>Công ty CP Quản lý Khách sạn & Dịch vụ Mandala - CN Mũi Né</span>
      </div>
      <div class="label">{{ 'print.company-address' | translate }}<span>Khu du lịch nghỉ dưỡng Apec Mandala Wyndham Mũi Né
        P.Mũi Né - TP Phan Thiết - Tỉnh Bình Thuận</span>
      </div>
      <div class="label">{{ 'print.company-tax' | translate }}<span>0108129263 -005</span></div>
    </div>
    <div style="width: fit-content;" class="fs-14">
      <div class="label">{{ 'print.ticket' | translate }}{{ data?.ticket_no }}</div>
      <div class="label">{{ 'print.payment-date' | translate }}{{ data?.payment_date | date: 'dd/MM/yyyy' }}</div>
    </div>
  </div>
  <h4 class="text-center" style="width: 100%; font-size: 24px; font-weight: 600; margin: 6px auto 16px auto">{{ 'print.payment-title' | translate }}</h4>
  <div>
    <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
      <div class="label">{{ 'print.receiver' | translate }}:</div>
      <div>{{ data?.payer }}</div>
    </div>
    <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
      <div class="label">{{ 'print.method' | translate }}:</div>
      <ng-container *ngFor="let item of listPaymentMethod">
        <div *ngIf="item.value === data?.payment_method">{{ item.text | translate }}</div>
      </ng-container>
    </div>
    <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
      <div class="label">{{ 'print.note' | translate }}:</div>
      <div>{{ data?.note }}</div>
    </div>
  </div>
  <div style="margin-bottom: 16px; margin-top: 16px;">{{ 'print.payment-detail' | translate }}</div>
  <div>
    <nz-table #table [nzData]="listPayment"
              nzTableLayout="auto" [nzBordered]="true" nzSize="small"
              [nzShowPagination]="false">
      <thead>
      <tr>
        <th style="width: 60px">{{ 'index' | translate }}</th>
        <th>{{ 'print.table.date' | translate }}</th>
        <th>{{ 'print.table.bill' | translate }}</th>
        <th>{{ 'print.table.package' | translate }}</th>
        <th>{{ 'print.table.total' | translate }}</th>
<!--        <th>{{ 'print.table.paid' | translate }}</th>-->
        <th>{{ 'print.table.received' | translate }}</th>
      </tr>
      </thead>
      <ng-container *ngIf="listPayment.length">
        <tbody>
        <tr *ngFor="let item of listPayment; let i = index">
          <td class="text-center" style="background-color: #F9FAFB; width: 60px;">{{ i + 1 }}</td>
          <td class="text-center">{{ item.payment_date | date: 'dd/MM/yyyy' }}</td>
          <td>{{ item.topup_no }}</td>
          <td>{{ item.package }}</td>
          <td>{{ item.payment_amount | number: '1.0-0' }}</td>
<!--          <td>{{ item.paid_amount | number: '1.0-0' }}</td>-->
          <td>{{ item.received_amount | number: '1.0-0' }}</td>
        </tr>
        <tr style="font-weight: 600;">
          <td class="text-center">{{ listPayment.length + 1 }}</td>
          <td class="text-center">{{ 'print.total' | translate }}</td>
          <td></td>
          <td></td>
          <td>{{ totalPaymentAmount | number }}</td>
<!--          <td>{{ totalPaidAmount | number }}</td>-->
          <td>{{ totalReceivedAmount | number }}</td>
        </tr>
        </tbody>
      </ng-container>
    </nz-table>
  </div>
  <div style="margin-top: 32px; width: 100%; min-height: 150px">
    <div style="float: right; width: fit-content; min-width: 400px;">
      <div class="fs-14 d-flex justify-content-between" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.pay-total' | translate }}</div>
        <div>{{ data?.total_cost ? (data?.total_cost | number) : 0 }}</div>
      </div>
      <div class="fs-14 d-flex" style="margin-bottom: 6px; gap: 8px">
        <div class="label">{{ 'print.amount-by-text' | translate }} {{ data?.total_cost | textCurrency }}</div>
      </div>
    </div>
  </div>
  <div style="margin-top: 32px" class="d-flex justify-content-between">
    <div style="width: 200px; text-align: center">
      <div style="margin-bottom: 60px; font-weight: 600">{{ 'print.bill-creator' | translate }}</div>
      <div>{{ data?.creator }}</div>
    </div>
    <div style="width: 200px; text-align: center">
      <div style="margin-bottom: 60px; font-weight: 600">{{ 'print.receiver' | translate }}</div>
      <div>{{ data?.payer }}</div>
    </div>
  </div>
</div>

import {Component} from '@angular/core';
import {DeleteStatus, GuestType, PaymentMethod} from "../../shared/shared.service";
import {TopUpDetail} from '../../shared/models/top-up-detail.model';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {Payment} from '../../shared/models/payment.model';

@Component({
  selector: 'app-form-print',
  templateUrl: './form-print.component.html',
  styleUrl: './form-print.component.scss'
})
export class FormPrintComponent {
  listPayment: any[] = [];
  data!: any;
  totalService!: number;
  totalNight!: number;
  totalDiscount!: number;
  totalAfterDiscount!: number;
  totalVAT!: number;
  totalPayment!: number;
  PaymentMethod = PaymentMethod;
  totalByCash = 0
  totalByTransfer = 0
  listGuestType = [
    {text: 'owner', value: GuestType.Owner},
    {text: 'guest', value: GuestType.Guest},
  ]
  payer: string = '';

  constructor(
    private service: ProxyDynamicService
  ) {
  }

  getTopUpDetail(): any {
    this.service.search('topup_detail', {
      fields: ['*.*.*'],
      filter: {
        topup_transaction: {'_eq': this.data.id},
        deleted: {'_eq': DeleteStatus.NO}
      }
    }).then(res => {
      this.listPayment = res.map((item: any) => {
        item['name'] = item.apartment.code;
        item.total = (item.night_count || 0) * (item.price || 0);
        this.getTotalAfterDiscount(item);
        return item;
      });
      this.getTotalBill();
    })
    this.service.search('payment', {
      fields: ['*.*.*'],
      filter: {
        'payment_detail': {'topup_transaction': this.data.id}, 'deleted': {'_eq': DeleteStatus.NO}
      }})
    .then((res: Payment[]) => {
      this.payer = res[0]?.payer || '';
      this.totalByCash = res.filter(r =>  r.payment_method === PaymentMethod.Cash).reduce((a: number, b: Payment) => {
        return a + b.payment_detail.reduce((next: number, child: any) => {
          return next + child.received_amount;
        }, 0);
      }, 0);
      this.totalByTransfer = res.filter(r =>  r.payment_method === PaymentMethod.Transfer).reduce((a: number, b: Payment) => {
        return a + b.payment_detail.reduce((next: number, child: any) => {
          return next + child.received_amount;
        }, 0);
      }, 0);
    });
  }

  getTotalAfterDiscount(item: TopUpDetail) {
    if (item.is_percent) {
      item.discount_cost = item.discount ? Math.round((item.total || 0) * item.discount / 100) : 0;
      item.total_after_discount = (item.total || 0) - (item.discount_cost || 0);
    } else {
      item.discount_cost = item.discount || 0;
      item.total_after_discount = (item.total || 0) - (item.discount_cost || 0);
    }
  }

  getTotalBill() {
    // this.totalService = this.listPayment.reduce((a, b) => {
    //   return a + (b.total || 0);
    // }, 0);
    // this.totalNight = this.listPayment.reduce((a, b) => {
    //   return a + (b.night_count || 0);
    // }, 0);
    // this.totalDiscount = this.listPayment.reduce((a, b) => {
    //   return a + (b.discount_cost || 0);
    // }, 0);
    // this.totalAfterDiscount = this.listPayment.reduce((a, b) => {
    //   return a + (b.total_after_discount || 0);
    // }, 0);
    // this.totalVAT = Math.round(this.totalAfterDiscount * 10 / 100);
    this.totalPayment = this.listPayment.reduce((a, b) => {
      return a + (b.total || 0);
    }, 0);
  }
}

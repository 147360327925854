import {Component, OnInit, ViewChild} from '@angular/core';
import {
  DeleteStatus,
  exportAsExcelFile,
  formatFilter,
  GuestType,
  ItemMode, MasterDataStatus,
  PaymentMethod,
  SharedService
} from '../shared/shared.service';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {BillingItemComponent} from './billing-item/billing-item.component';
import {DatePipe} from '@angular/common';
import moment from 'moment/moment';
import {FormPrintComponent} from './form-print/form-print.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrl: './billing.component.scss'
})
export class BillingComponent implements OnInit {
  listPaymentMethod: any[] = [
    {text: 'cash', value: PaymentMethod.Cash},
    {text: 'transfer', value: PaymentMethod.Transfer},
  ];
  tableConfig: any[] = [];
  filterConfig: any[] = [];
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-ticket_no',
    payment_date: null,
    ticket_no: null,
    owner: null,
    payment_method: null
  };
  source: any[] = [];
  listApartment: any[] = [];
  listPackage: any[] = [];
  listOwner: any[] = [];
  totalRows: number = 0;
  @ViewChild('formPrintComponent') formPrintComponent!: FormPrintComponent;

  constructor(
    private service: ProxyDynamicService,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getMasterData();
  }

  getMasterData() {
    this.sharedService.showLoading(true);
    this.service.search('owner', {filter: {deleted: {'_eq': DeleteStatus.NO}}}).then(e => {
      const listOwner = e.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      });
      this.tableConfig = [
        {name: 'ticket_no', title: 'billing.code', default: null, type: 'textAsLink', sortBy: 'ASC'},
        {name: 'payment_date', title: 'billing.date', default: null, type: 'date', sortBy: 'ASC'},
        {name: 'payer', title: 'billing.payer', default: null, type: 'text', sortBy: 'ASC'},
        {name: 'payment_method', title: 'payment.type', default: null, type: 'dropdown', options: this.listPaymentMethod, sortBy: 'ASC'},
        {name: 'total_cost', title: 'billing.cost', default: null, type: 'number'},
        {name: 'creator', title: 'creator', default: null, type: 'text', col: 12, viewOnly: true},
        {name: 'created_at', title: 'created', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
        {name: 'updater', title: 'updater', default: null, type: 'text', col: 12, viewOnly: true},
        {name: 'updated_at', title: 'updated', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
      ];
      this.filterConfig = [
        {name: 'ticket_no', title: 'billing.code', default: '', type: 'text', col: 6},
        {name: 'payment_date', title: 'billing.date', default: null, type: 'date', col: 6},
        {name: 'payer', title: 'billing.payer', default: '', type: 'text', col: 6},
        {name: 'payment_method', title: 'payment.type', default: null, type: 'dropdown', col: 4, options: this.listPaymentMethod},
        {name: 'deleted', label: 'deleted', default: '', type: 'checkbox', col: 2},
      ];
      this.listOwner = listOwner;
      this.sharedService.showLoading(false);
      this.getData();
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    this.service.searchWithMeta('payment', {
      fields: ['*.*.*.*'],
      filter: formatFilter(this.filter, this.filterConfig),
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map(item => {
        item['total_cost'] = item['payment_detail'].length ? item['payment_detail'].reduce((a: number, b: any) => {
          return a + b.received_amount
        }, 0) : null;
        item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
        item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BillingItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-billing',
      nzData: {
        mode: ItemMode.Edit,
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPaymentMethod: this.listPaymentMethod,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
        this.translate.instant('top-up.updateSuccess'));
      }
      this.getData();
    });
  }

  delete($event: any) {
    this.service.deleteItemBilling($event.id).subscribe(res => {
      this.noti.create('success', '',
        this.translate.instant('billing.deleteSuccess'));
      this.getData();
    }, (error: any) => {
      this.noti.error(error.errors.length ? error.errors[0].message : '', '')
    });
  }

  view($event: any) {
    const modalRef = this.modalService.create({
      nzContent: BillingItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-billing',
      nzData: {
        mode: ItemMode.View,
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPaymentMethod: this.listPaymentMethod,
        data: $event
      },
    });
    modalRef.afterClose.subscribe(result => {
        if (result && result.print) {
          this.onPrint(result.print);
        }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: BillingItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-billing',
      nzData: {
        mode: ItemMode.Add,
        listOwner: this.listOwner.filter(r => r.status === MasterDataStatus.Active),
        listPaymentMethod: this.listPaymentMethod,
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('billing.addSuccess'));
        this.getData();
      }
    });
  }

  export() {
    this.service.search('payment', {
      "limit": this.totalRows,
      "page": 0,
      'sort': [this.filter['sort']],
      fields: ['*.*', 'user_created.*', 'user_updated.*'],
      filter: formatFilter(this.filter, this.filterConfig),
    }).then(dataResult => {
      let configColumn = this.tableConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if(dataResult?.length > 0) {
        dataResult.map(item => {
          item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
          item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
          item['total_cost'] = item['payment_detail'].length ? item['payment_detail'].reduce((a: number, b: any) => {
            return a + b.paid_amount
          }, 0) : null;
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = typeof data[e.field] === "number" ? data[e.field].toString() : (data[e.field] || '');
            if ((e.field === 'date_created' || e.field === 'date_updated') && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'DD/MM/YYYY ');
            }
            if (e.field === 'payment_method' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listPaymentMethod.find((r: any) => r.value === data[e.field])?.text || '');
            }
            if (e.field === 'owner' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = data[e.field].code + ' - ' + data[e.field].name;
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('billing.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      if (error?.errors && error?.errors.length) {
        this.service.handleError(error)
      } else {
        // this.noti.error()
      }
    });
  }

  onSort(event: any) {
    this.tableConfig.forEach((col) => {
      if (col['name'] === event) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }

  onPrint(event: any) {
    this.sharedService.showLoading(true);
    this.formPrintComponent.data = event;
    this.service.getListTopUpByPaymentNo({'payment_no': event.ticket_no}).subscribe((res) => {
      if (res.data.length) {
        this.formPrintComponent.listPayment = res.data;
        this.formPrintComponent.totalPaymentAmount = res.data.reduce((a: number, b: any) => {
          return a + Number(b.payment_amount);
        }, 0);
        this.formPrintComponent.totalPaidAmount = res.data.reduce((a: number, b: any) => {
          return a + Number(b.paid_amount);
        }, 0);
        this.formPrintComponent.totalReceivedAmount = res.data.reduce((a: number, b: any) => {
          return a + Number(b.received_amount);
        }, 0);
      } else {
        this.formPrintComponent.listPayment = [];
      }
    }, (error: any) => {
     this.service.handleError(error);
    });
    setTimeout(() => {
      this.sharedService.showLoading(false);
      setTimeout(() => {
        window.print()
      }, 500);
    }, 1000);
  }
}

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {ProxyDynamicService} from '../shared/service/proxy-dynamic.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private service: ProxyDynamicService,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      password: [null, Validators.required],
      re_password: [null, Validators.required]
    })
  }

  changePassword() {
    if (this.formGroup.valid && this.checkMatchPassword()) {
      this.service.changePassword(this.formGroup.controls['password'].value).then((res: any) => {
        console.log(res);
        this.noti.success('', this.translate.instant('success-password'));
        return this.router.navigate(['/']);
      });
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  checkMatchPassword() {
    if (this.formGroup.controls['password'].value !== this.formGroup.controls['re_password'].value) {
      this.noti.create('error', '', this.translate.instant('error-password'));
      return false;
    }
    return true;
  }
}

<div class="container-fluid bg-white px-0" style="height: 1000vh">
  <div class="py-2 px-3">
    <h3 class="mb-0"> {{ 'change-password' | translate }}</h3>
  </div>
  <form class="p-2 mx-0" nz-row [nzGutter]="[16, 16]" [formGroup]="formGroup">
    <div nz-col nzSpan="8">
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col nzSpan="24">
          <app-control formControlName="password"
                       [controlConfig]="{max: 20, title: 'new-password', type: 'password', required: true}"></app-control>
        </div>
        <div nz-col nzSpan="24">
          <app-control formControlName="re_password"
                       [controlConfig]="{max: 20, title: 're-new-password', type: 're-password', required: true}"></app-control>
        </div>
      </div>
    </div>
  </form>
  <button nz-button nzType="primary" class="mx-3 mt-2" (click)="changePassword()">{{ 'save' | translate }}</button>
</div>

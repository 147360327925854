<form [formGroup]="formGroup">
  <div class="bg-white p-3" style="border-radius: 4px">
    <h4>{{ 'billing.main-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16, 16]" *ngIf="mode !== itemMode.View">
      <div nz-col nzSpan="12" *ngIf="mode === itemMode.Edit">
        <app-control formControlName="ticket_no"
                     [controlConfig]="{title: 'billing.code', type: 'text', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12" *ngIf="mode === itemMode.Edit">
        <app-control formControlName="payment_date" [max]="currentDateMax"
                     [controlConfig]="{title: 'billing.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="24" *ngIf="mode === itemMode.Add">
        <app-control formControlName="payment_date" [max]="currentDateMax"
                     [controlConfig]="{title: 'billing.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="owner" [options]="listOwner" (getMoreData)="getMoreDataOwner($event)" (dropdownSearch)="filterDataOwner($event)"
                     [controlConfig]="{title: 'billing.payer', type: 'dropdownServer', required: true, options: listOwner}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="payment_method" [options]="listPaymentMethod"
                     [controlConfig]="{title: 'payment.type', type: 'dropdown', required: true, options: listPaymentMethod}"></app-control>
      </div>
      <div nz-col nzSpan="24">
        <app-control formControlName="note" [controlConfig]="{title: 'note', type: 'textarea'}"></app-control>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]" *ngIf="mode === itemMode.View">
      <div nz-col nzSpan="6">
        <div>{{ 'billing.code' | translate }}</div>
        <div class="content">{{ data.ticket_no }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'billing.date' | translate }}</div>
        <div class="content">{{ data.payment_date | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'billing.payer' | translate }}</div>
        <div class="content">{{ data.payer }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'payment.type' | translate }}</div>
        <ng-container *ngFor="let itemPayment of listPaymentMethod">
          <div class="content" *ngIf="itemPayment.value === data.payment_method">{{ itemPayment.text | translate }}</div>
        </ng-container>
      </div>
<!--      <div nz-col nzSpan="24">-->
<!--        <div>{{ 'note' | translate }}</div>-->
<!--        <div>{{ data.note }}</div>-->
<!--      </div>-->
      <div nz-col nzSpan="6">
        <div>{{ 'created' | translate }}</div>
        <div class="content">{{ (data.date_created || data.created_at) | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'creator' | translate }}</div>
        <div class="content">{{ data.creator }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'updated' | translate }}</div>
        <div class="content">{{ (data.date_updated || data.updated_at) | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'updater' | translate }}</div>
        <div class="content">{{ data.updater }}</div>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-3" style="border-radius: 4px">
    <div class="w-100 d-flex justify-content-between">
      <h4 class="mb-0">{{ 'billing.payment-list' | translate }}</h4>
<!--      <div>-->
<!--        <lucide-icon [name]="expand ? 'chevron-up' : 'chevron-down'" (click)="expand = !expand"></lucide-icon>-->
<!--      </div>-->
    </div>
    <div *ngIf="expand" class="mt-3">
      <nz-table #table [nzData]="listPayment" nzTableLayout="auto" [nzBordered]="true" nzSize="small" [nzShowPagination]="false">
        <thead>
        <tr>
          <th style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'top-up.date' | translate }}</th>
          <th>{{ 'top-up.ticket' | translate }}</th>
          <th>{{ 'service-package.label' | translate }}</th>
          <th>{{ 'billing.price' | translate }}</th>
<!--          <th>{{ 'payment.paid' | translate }}</th>-->
          <th *ngIf="mode !== itemMode.View">{{ 'payment.left' | translate }}</th>
          <th>{{ 'billing.cost' | translate }}</th>
        </tr>
        </thead>
        <ng-container *ngIf="listPayment.length">
          <tbody>
          <tr *ngFor="let item of listPayment; let i = index">
            <td class="text-center" style="background-color: #F9FAFB; width: 60px;">{{ i + 1 }}</td>
            <td>{{ item.payment_date | date: 'dd/MM/yyyy' }}</td>
            <td>{{ item.topup_no }}</td>
            <td>{{ item.package }}</td>
            <td>{{ item.payment_amount | number: '1.0-0' }}</td>
<!--            <td>{{ item.paid_amount | number: '1.0-0' }}</td>-->
            <td *ngIf="mode !== itemMode.View">{{ item.due_amount | number: '1.0-0' }}</td>
            <td>
              <div *ngIf="mode === itemMode.View">{{ item.received_amount | number: '1.0-0' }}</div>
              <app-model *ngIf="mode !== itemMode.View" [value]="item.received_amount" (modelValueChange)="onChangeReceivedAmount(item, $event)"
                         [controlConfig]="{type: 'number', label: 'billing.cost', max: item.due_amount}"></app-model>
            </td>
          </tr>
          </tbody>
        </ng-container>
      </nz-table>

      <ng-container *ngIf="!listPayment.length">
        <div style="width: 100%; text-align: center">
          <ord-grid-not-data></ord-grid-not-data>
        </div>
      </ng-container>
      <div class="mt-3 w-100" style="height: 24px">
        <div class="fs-14 d-flex justify-content-between" style="width: 25%; float: right">
          <div>{{ 'billing.total-due' | translate }}</div>
          <div>{{ listPayment.length ? (totalDue ? (totalDue | number: '1.0-0') : 0) : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</form>
<div *nzModalTitle class="title-modal">{{ ('billing.' + mode) |translate }}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
<!--    <button nz-button nzType="primary" (click)="editMode()">{{ 'edit' | translate }}</button>-->
    <button *ngIf="!nzModalData.disabledPrint" nz-button nzType="primary" (click)="print()">{{ 'button.print'| translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode !== itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
<!--    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>-->
  </ng-container>
</div>

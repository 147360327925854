import {Component, inject} from '@angular/core';
import {BookingStatus, DeleteStatus, GuestType, ItemMode, PaymentMethod} from "../../shared/shared.service";
import {TopUpDetail} from '../../shared/models/top-up-detail.model';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {Payment} from '../../shared/models/payment.model';
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {BookingItemComponent} from '../../booking/booking-item/booking-item.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrl: './popup-confirm.component.scss'
})
export class PopupConfirmComponent {
  readonly nzModalData = inject(NZ_MODAL_DATA);

  constructor(
    private modalRef: NzModalRef,
    private service: ProxyDynamicService,
    private modalService: NzModalService
  ) {
    console.log(this.nzModalData)
  }

  destroyModal(bool: boolean) {
    this.modalRef.destroy(bool);
  }

  viewDetailBooking(bookingData: any) {
    this.service.search('building', {filter: {deleted: {'_eq': DeleteStatus.NO}}}).then((builings: any) => {
      this.modalService.create({
        nzContent: BookingItemComponent,
        nzWidth: '60vw',
        nzClassName: 'modal-booking',
        nzData: {
          mode: ItemMode.View,
          notAllowEdit: true,
          listBuilding: builings.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;}),
          listStatus: [
            {text: 'status.booked', value: BookingStatus.Booked, class: 'booked'},
            {text: 'status.in-house', value: BookingStatus.InHouse, class: 'active'},
            {text: 'status.canceled', value: BookingStatus.Canceled, class: 'canceled'},
            {text: 'status.back-early', value: BookingStatus.BackEarly, class: 'inactive'},
            {text: 'status.finished', value: BookingStatus.Finished, class: 'finished'}
          ],
          data: bookingData
        },
      });
    })
  }
}

import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  exportAsExcelFile,
  formatFilter,
  ItemMode,
  ListStatus,
  MasterDataStatus,
  REGEX,
  SharedService
} from '../../../shared/shared.service';
import {ItemComponent} from '../item/item.component';
import {ProxyDynamicService} from '../../../shared/service/proxy-dynamic.service';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {Project} from '../../../shared/models/project.model';

@Component({
  selector: 'app-projects',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  providers: [DatePipe]
})
export class ListComponent implements OnInit {
  listStatus = ListStatus;
  formConfig: any[] = [
    {name: 'code', title: 'project.code', default: null,  type: 'textAsLink', max: 25, regex: REGEX.code, col: 12, required: true, sortBy: 'ASC', hint: true, hintLabel: 'input-hint.code'},
    {name: 'name', title: 'project.name', default: null, type: 'text', max: 200, col: 12, required: true, sortBy: 'ASC'},
    {name: 'investor', title: 'project.owner', default: null, type: 'text', max: 200, col: 12, required: true, sortBy: 'ASC'},
    {name: 'address', title: 'address', default: null, type: 'text', max: 250, col: 12, required: true, sortBy: 'ASC'},
    {name: 'total_apartment', title: 'project.total-apartment', default: null, type: 'number', maxlength: 5, col: 12, sortBy: 'ASC'},
    {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 12, required: true, options: this.listStatus, sortBy: 'ASC'},
    {name: 'creator', title: 'creator', default: null, type: 'text', col: 12, viewOnly: true},
    {name: 'date_created', title: 'created', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
    {name: 'updater', title: 'updater', default: null, type: 'text', col: 12, viewOnly: true},
    {name: 'date_updated', title: 'updated', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
  ];
  formFilterConfig = [
    {name: 'code', title: 'project.code', default: '', type: 'text', col: 8},
    {name: 'name', title: 'project.name', default: '', type: 'text', col: 8},
    {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 8, options: this.listStatus},
  ];
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-code',
    code: null,
    name: null,
    status: MasterDataStatus.Active
  };
  source: any[] = [];
  totalRows: number = 0;

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private noti: NzNotificationService,
    private service: ProxyDynamicService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getData(this.filter);
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    this.service.searchWithMeta('project', {
      fields: ['*', 'user_created.*', 'user_updated.*'  ],
      filter: formatFilter(this.filter, this.formFilterConfig),
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map((item: Project) => {
        item['creator'] = item['user_created'] ? [item['user_created']['first_name'], item['user_created']['last_name']].join(' ') : undefined;
        item['updater'] = item['user_updated'] ? [item['user_updated']['first_name'], item['user_updated']['last_name']].join(' ') : undefined;
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onChangePage(event: any) {
    this.filter.page = event - 1;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-project',
      nzData: {
        mode: ItemMode.Edit,
        formConfig: this.formConfig,
        data: item,
        collection: 'project'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('updateSuccess', {value: (this.translate.instant('project.label'))}));
        this.getData();
      }
    });
  }

  view(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-project',
      nzData: {
        mode: ItemMode.View,
        formConfig: this.formConfig,
        data: item,
        collection: 'project'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('updateSuccess', {value: (this.translate.instant('project.label'))}));
        this.getData();
      }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '50vw',
      nzClassName: 'modal-project',
      nzData: {
        mode: ItemMode.Add,
        formConfig: this.formConfig,
        collection: 'project'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
          this.translate.instant('addSuccess', {value: (this.translate.instant('project.label'))}));
        this.getData();
      }
    });
  }

  delete(item: any) {
    this.service.deleteItemProject(item.id).subscribe(res => {
      this.noti.create('success', '',
        this.translate.instant('deleteSuccess', {value: (this.translate.instant('project.label'))}));
      this.getData();
    }, (error: any) => {
      this.service.handleError(error)
    });
  }

  export() {
    this.sharedService.showLoading(true);
    this.service.search('project', {
      "limit": this.totalRows,
      "page": 0,
      'sort': [this.filter['sort']],
      fields: ['*', 'user_created.*', 'user_updated.*'],
      filter: formatFilter(this.filter, this.formFilterConfig),
    }).then(dataResult => {
      let configColumn = this.formConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if(dataResult?.length > 0) {
        dataResult.map(item => {
          item['creator'] = item['user_created'] ? [item['user_created']['first_name'], item['user_created']['last_name']].join(' ') : undefined;
          item['updater'] = item['user_updated'] ? [item['user_updated']['first_name'], item['user_updated']['last_name']].join(' ') : undefined;
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = typeof data[e.field] === "number" ? data[e.field].toString() : (data[e.field] || '');
            if ((e.field === 'date_created' || e.field === 'date_updated') && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'DD/MM/YYYY ');
            }
            if (e.field === 'status' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listStatus.find(r => r.value === data[e.field])?.text  || '')
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('project.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
      this.sharedService.showLoading(false);
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.formConfig.forEach((col) => {
      if (col['name'] === event) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }
}

<div class="title-modal text-center">
  <div><h4>{{ 'top-up.confirm-booking-1' | translate }}
    <ng-container *ngFor="let booking of nzModalData.data">
      <a href="javascript:void(0)" class="textAsLink" (click)="viewDetailBooking(booking)">{{ booking.booking_no }} </a>
    </ng-container>
  </h4></div>
  <div><h4>{{ 'top-up.confirm-booking-2' | translate }}</h4></div>
</div>
<div *nzModalFooter>
  <button nz-button class="btn-cancel-modal" (click)="destroyModal(false)">{{ 'cancel' | translate }}</button>
  <button nz-button nzType="primary" (click)="destroyModal(true)">{{ 'save' | translate }}</button>
</div>

import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {distinctUntilChanged, map, Subject, take} from 'rxjs';
import {IdReadInfoType} from '../models/idRead.model';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {
  constructor(
    private socket: Socket
  ) {
  }
  private connectStateSubject: Subject<boolean> = new Subject<boolean>();

  createWebSocket(url: string) {
    this.socket = new Socket({url: url, options: {
      transports: ['websocket'], upgrade: false
      }});
    this.socket.on('connect', () => {
      console.log('WebSocket connection established');
      this.connectStateSubject.next(true);
    })
    this.socket.on('/info', (event: MessageEvent) => {
      console.log('WebSocket message received:', event.data);
      this.socket.emit('/info', event.data);
    });
    this.socket.on('/event', (event: any) => {
      if (event.id === IdReadInfoType.ID_INFO) {
        this.socket.emit('/event', event);
      }
    })
    this.socket.on('error', (error: any) => {
      console.error('WebSocket error:', error);
    });

    this.socket.on('close', (event: CloseEvent) => {
      console.log('WebSocket connection closed:', event);
      console.log('WebSocket closed, attempting reconnection...');
    });
  }

  getMessage(message: string) {
    return this.socket.fromEvent(message).pipe(distinctUntilChanged(), map((data: any) => data))
  }

  // Observable to listen to connection state
  getConnectState() {
    return this.connectStateSubject.asObservable();
  }
  reconnect() {
    return this.socket.connect();
  }
}

import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {
  DeleteStatus, Duration,
  exportAsExcelFile,
  formatFilter, GuestType,
  ItemMode,
  ListOperate,
  ListStatus,
  MasterDataStatus,
  OperateType,
  REGEX, SharedService
} from '../../../shared/shared.service';
import {ItemComponent} from '../item/item.component';
import {ProxyDynamicService} from '../../../shared/service/proxy-dynamic.service';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {forkJoin, map} from 'rxjs';

@Component({
  selector: 'app-service-package',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  providers: [DatePipe]
})
export class ListComponent implements OnInit {
  listStatus = ListStatus;
  listOperate = ListOperate;
  listApartmentType: any[] = [];
  listService: any[] = [];
  listGuestType = [
    {text: 'owner.label', value: GuestType.Owner},
    {text: 'guest', value: GuestType.Guest},
  ]
  listFrequency = [
    {text: 'service-package.freq.month', value: Duration.MONTH},
    {text: 'service-package.freq.precious', value: Duration.PRECIOUS}
  ]
  listDuration = [
    {text: 'service-package.freq.month', value: Duration.MONTH},
    {text: 'service-package.freq.year', value: Duration.YEAR},
  ]
  filter: any = {
    limit: 15,
    page: 0,
    sort: '-code',
    code: null,
    name: null,
    status: MasterDataStatus.Active
  };
  source: any[] = [];
  formConfig = [
    {name: 'code', title: 'service-package.code', default: null, col: 12, type: 'textAsLink', required: true, sortBy: 'ASC'},
    {name: 'name', title: 'service-package.name', default: null, col: 12, type: 'text', required: true, sortBy: 'ASC'},
    {name: 'note', title: 'description', default: null, col: 12, type: 'text', sortBy: 'ASC'},
    {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 12, options: this.listStatus, required: true, sortBy: 'ASC'},
    {name: 'creator', title: 'creator', default: null, type: 'text', col: 12, viewOnly: true},
    {name: 'date_created', title: 'created', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true},
    {name: 'updater', title: 'updater', default: null, type: 'text', col: 12, viewOnly: true},
    {name: 'date_updated', title: 'updated', default: moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate(), type: 'date', col: 12, viewOnly: true, format: 'DD/MM/YYYY hh:mm:ss', showTime: true}
  ];
  formFilterConfig = [
    {name: 'code', title: 'service-package.code', default: '', type: 'text', col: 8},
    {name: 'name', title: 'service-package.name', default: '', type: 'text', col: 8},
    {name: 'status', title: 'status.title', default: MasterDataStatus.Active, type: 'status', col: 8, options: this.listStatus},
  ];
  totalRows: number = 0;

  constructor(
      private translate: TranslateService,
      private modalService: NzModalService,
      private noti: NzNotificationService,
      private service: ProxyDynamicService,
      private datePipe: DatePipe,
      private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getParentList();
  }

  getParentList() {
    this.sharedService.showLoading(true);
    const listAPI = [
      this.service.search('apartment_type'),
      this.service.search('service')]
    forkJoin(listAPI).pipe(map(([listApartmentType, listService]) => ({
      listApartmentType: listApartmentType.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      }),
      listService: listService.map((item: any) => {
        item['text'] = item.code + ' - ' + item.name;
        item['value'] = item.id;
        return item;
      })
    }))).subscribe(e => {
      this.listApartmentType = e.listApartmentType.filter((r: any) => r.status === MasterDataStatus.Active && r.deleted === DeleteStatus.NO);
      this.listService = e.listService.filter((r: any) => r.status === MasterDataStatus.Active && r.deleted === DeleteStatus.NO);
      this.sharedService.showLoading(false);
      this.getData(this.filter);
    });
  }

  getData(event?: any) {
    this.sharedService.showLoading(true);
    if (event) {
      Object.keys(event).forEach((key: any) => {
        this.filter[key] = event[key];
      })
    }
    const filter = formatFilter(this.filter, this.formFilterConfig);
    this.service.searchWithMeta('package', {
      fields: ['*', 'created_by.*', 'updated_by.*', 'package_price.*', 'service_apply.*'],
      filter: filter,
      "limit": this.filter.limit,
      "page": this.filter.page,
      'sort': [this.filter['sort']]
    }).then(res => {
      this.totalRows = res.total;
      this.source = res?.data?.map(item => {
        item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
        item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
        return item;
      });
      this.sharedService.showLoading(false);
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onChangePage(event: any) {
    this.filter.page = event;
    this.getData();
  }

  onChangeSize(event: any) {
    this.filter.limit = event;
    this.getData();
  }

  edit(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '70vw',
      nzClassName: 'modal-package-service',
      nzData: {
        mode: ItemMode.Edit,
        formConfig: this.formConfig,
        listApartmentType: this.listApartmentType,
        listOperate: this.listOperate,
        listService: this.listService,
        listStatus: this.listStatus,
        listGuestType: this.listGuestType,
        listFrequency: this.listFrequency,
        listDuration: this.listDuration,
        data: item,
        collection: 'package'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('updateSuccess', {value: (this.translate.instant('service-package.label'))}));
        this.getData()
      }
    });
  }

  view(item: any) {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '70vw',
      nzClassName: 'modal-package-service',
      nzData: {
        mode: ItemMode.View,
        formConfig: this.formConfig,
        listApartmentType: this.listApartmentType,
        listOperate: this.listOperate,
        listService: this.listService,
        listStatus: this.listStatus,
        listGuestType: this.listGuestType,
        listFrequency: this.listFrequency,
        listDuration: this.listDuration,
        data: item,
        collection: 'package'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('updateSuccess', {value: (this.translate.instant('service-package.label'))}));
        this.getData();
      }
    });
  }

  add() {
    const modalRef = this.modalService.create({
      nzContent: ItemComponent,
      nzWidth: '70vw',
      nzClassName: 'modal-package-service',
      nzData: {
        mode: ItemMode.Add,
        formConfig: this.formConfig,
        listApartmentType: this.listApartmentType,
        listOperate: this.listOperate,
        listService: this.listService,
        listStatus: this.listStatus,
        listGuestType: this.listGuestType,
        listFrequency: this.listFrequency,
        listDuration: this.listDuration,
        collection: 'package'
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '',
            this.translate.instant('addSuccess', {value: (this.translate.instant('service-package.label'))}));
        this.getData()
      }
    });
  }

  delete(item: any) {
    this.service.deleteItemPackage(item.id).subscribe(res => {
      this.noti.create('success', '',
          this.translate.instant('deleteSuccess', {value: (this.translate.instant('service-package.label'))}));
      this.getData()
    }, (error: any) => {
      this.service.handleError(error)
    });
  }

  export() {
    this.sharedService.showLoading(true);
    this.service.search('package', {
      "limit": this.totalRows,
      "page": 0,
      'sort': [this.filter['sort']],
      fields: ['*', 'created_by.*', 'updated_by.*'],
      filter: formatFilter(this.filter, this.formFilterConfig),
    }).then(dataResult => {
      let configColumn = this.formConfig.map((item: any) => {
        return {
          headerText: item.title,
          field: item.name
        }
      })
      let listExport: any[] = [];
      if(dataResult?.length > 0) {
        dataResult.map(item => {
          item['creator'] = item['created_by'] ? [item['created_by']['first_name'], item['created_by']['last_name']].join(' ') : undefined;
          item['updater'] = item['updated_by'] ? [item['updated_by']['first_name'], item['updated_by']['last_name']].join(' ') : undefined;
          return item;
        }).forEach((data: any) => {
          let item: any = {};
          configColumn.forEach(e => {
            item[this.translate.instant(e.headerText)] = typeof data[e.field] === "number" ? data[e.field].toString() : (data[e.field] || '');
            if ((e.field === 'date_created' || e.field === 'date_updated') && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.datePipe.transform(data[e.field], 'DD/MM/YYYY ');
            }
            if (e.field === 'status' && data[e.field]) {
              item[this.translate.instant(e.headerText)] = this.translate.instant(this.listStatus.find(r => r.value === data[e.field])?.text  || '')
            }
          })
          listExport.push(item);
        })
      } else {
        let item: any = {};
        configColumn.forEach(e => {
          item[this.translate.instant(e.headerText)] = '';
          listExport.push(item);
        })
      }
      exportAsExcelFile(listExport, this.translate.instant('service-package.title') + '_' + this.datePipe.transform(new Date(), 'yyMMdd'));
      this.sharedService.showLoading(false);
    }, (error: any) => {
      this.service.handleError(error)
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  onSort(event: any) {
    this.formConfig.forEach((col) => {
      if (col['name'] === event) {
        this.filter['sort'] = col['sortBy'] === 'ASC' ? event : '-' + event;
        col['sortBy'] = col['sortBy'] === 'ASC' ? 'DESC' : 'ASC';
      } else {
        col['sortBy'] = 'ASC'
      }
    });
    this.getData();
  }
}

<form [formGroup]="formGroup">
  <div nz-row [nzGutter]="[16, 16]" *ngIf="mode !== itemMode.View">
    <div nz-col nzSpan="12">
      <app-control formControlName="full_name" [controlConfig]="{title: 'booking.guest-name', max: 255, type: 'text', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="phone" [controlConfig]="{title: 'phone', type: 'text-number'}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="gender" [options]="listGender" [controlConfig]="{title: 'gender.title', type: 'radio-group', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="birthday" [max]="currentDate" [controlConfig]="{title: 'birthday', type: 'date', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="doc_type" [options]="listDocType" [controlConfig]="{title: 'doc.type', type: 'dropdown', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="doc_no" [controlConfig]="{title: 'doc.code', type: 'text', required: true, regex: REGEX.code}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="issue_date" [max]="f['expired_date'].value" [controlConfig]="{title: 'doc.date', type: 'date', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="issue_place" [controlConfig]="{title: 'doc.place', type: 'text', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="expired_date" [min]="f['issue_date'].value" [controlConfig]="{title: 'doc.expire', type: 'date', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <app-control formControlName="address" [controlConfig]="{title: 'address', type: 'text', required: true}"></app-control>
    </div>
    <div nz-col nzSpan="12">
      <label><span>{{ 'booking.img' | translate }}</span></label>
      <div class="d-flex gap-2 mb-1 ">
        <image-upload [imageSrc]="f['doc_img_front'].value" [label]="'booking.img-front'" [idImg]="'sharer_front'"
                      (onUpload)="handleFileUpload('doc_img_front', $event)" [size]="'small'"></image-upload>
        <image-upload [imageSrc]="f['doc_img_back'].value" [label]="'booking.img-back'" [idImg]="'sharer_back'"
                      (onUpload)="handleFileUpload('doc_img_back', $event)" [size]="'small'"></image-upload>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="[16, 16]" *ngIf="mode === itemMode.View">
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'booking.guest-name' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.full_name }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'phone' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.phone }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'doc.type' | translate }}
        </div>
        <div nz-col nzSpan="12">
          <ng-container *ngFor="let item of listDocType">
            <span *ngIf="item.value === data.doc_type">{{ item.text | translate }}</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'doc.code' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.doc_no }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'birthday' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.birthday | date: 'dd/MM/yyyy' }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'gender.title' | translate }}
        </div>
        <div nz-col nzSpan="12">
          <ng-container *ngFor="let item of listGender">
            <span *ngIf="item.value === data.gender">{{ item.text | translate }}</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'doc.date' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.issue_date | date: 'dd/MM/yyyy' }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'doc.place' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.issue_place }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'address' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.address }}
        </div>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div nz-row [nzGutter]="[16, 16]">
        <div class="title" nz-col nzSpan="12">
          {{ 'doc.expire' | translate }}
        </div>
        <div nz-col nzSpan="12">
          {{ data.expired_date | date: 'dd/MM/yyyy' }}
        </div>
      </div>
    </div>
  </div>
</form>
<div *nzModalTitle class="title-modal">{{ mode |translate }} {{ 'booking.share-guest-info' | translate }} {{nzModalData.index}}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="editMode()">{{ 'edit' | translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode == itemMode.Edit || mode == itemMode.Add">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button *ngIf="!nzModalData.lastGuest" nz-button nzType="primary" (click)="onSave(true)">{{ 'next' | translate }}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>
  </ng-container>
</div>

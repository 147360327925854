<form [formGroup]="formGroup">
  <div class="bg-white p-3" style="border-radius: 4px">
    <h4>{{ 'top-up.main-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16, 16]" *ngIf="mode !== itemMode.View">
      <div nz-col nzSpan="12" *ngIf="mode === itemMode.Edit">
        <app-control formControlName="ticket_no"
                     [controlConfig]="{title: 'top-up.code', type: 'text', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12" *ngIf="mode === itemMode.Edit">
        <app-control formControlName="deposit_date" [max]="currentDateMax"
                     [controlConfig]="{title: 'top-up.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="24" *ngIf="mode === itemMode.Add">
        <app-control formControlName="deposit_date" [max]="currentDateMax"
                     [controlConfig]="{title: 'top-up.date', type: 'date', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="apartment" [options]="listApartment" (getMoreData)="getMoreDataApartment($event)" (dropdownSearch)="filterDataApartment($event)"
                     [controlConfig]="{title: 'apartment.code', type: 'multi-select', required: true, options: listApartment}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="owner" [options]="listOwner" (getMoreData)="getMoreDataOwner($event)" (dropdownSearch)="filterDataOwner($event)"
                     [controlConfig]="{title: 'owner.label', type: 'multi-select', required: true, options: listOwner}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="package_id" [options]="listPackage"
                     [controlConfig]="{title: 'service-package.label', type: 'dropdown', required: true, options: listPackage}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="guest_type" [options]="listGuestType"
                     [controlConfig]="{title: 'top-up.guest-type', type: 'dropdown', required: true, options: listGuestType}"></app-control>
      </div>
      <div nz-col nzSpan="24">
        <app-control formControlName="note" [controlConfig]="{title: 'note', type: 'textarea'}"></app-control>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]" *ngIf="mode === itemMode.View">
      <div nz-col nzSpan="6">
        <div>{{ 'top-up.code' | translate }}</div>
        <div class="content">{{ data.ticket_no }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'top-up.date' | translate }}</div>
        <div class="content">{{ data.deposit_date | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'top-up.guest-type' | translate }}</div>
        <ng-container *ngFor="let item of listGuestType">
          <div class="content" *ngIf="item.value === data.guest_type">{{ item.text | translate }}</div>
        </ng-container>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'service-package.label' | translate }}</div>
        <div class="content">{{ data.package_name }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'billing.payer' | translate }}</div>
        <div class="content">{{ listBillingDetail.length ? listBillingDetail[0].payer : '' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'apartment.label' | translate }}</div>
        <div class="content">{{ data.apartment_codes }}</div>
      </div>
      <div nz-col nzSpan="12">
        <div>{{ 'note' | translate }}</div>
        <div class="content">{{ data.note }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'created' | translate }}</div>
        <div class="content">{{ data.date_created | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'creator' | translate }}</div>
        <div class="content">{{ data.created_by }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'updated' | translate }}</div>
        <div class="content">{{ data.date_updated | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
      </div>
      <div nz-col nzSpan="6">
        <div>{{ 'updater' | translate }}</div>
        <div class="content">{{ data.updated_by }}</div>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-3" style="border-radius: 4px">
    <div class="w-100 d-flex justify-content-between">
      <h4 class="mb-0">{{ 'top-up.detail-info' | translate }}</h4>
      <div>
        <lucide-icon [name]="expand ? 'chevron-up' : 'chevron-down'" (click)="expand = !expand"></lucide-icon>
      </div>
    </div>
    <div *ngIf="expand" class="mt-3">
      <nz-table #table [nzData]="listPayment"
                nzTableLayout="auto" [nzBordered]="true" nzSize="small"
                [nzShowPagination]="false">
        <thead>
        <tr>
          <th class="text-center" style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'apartment.code' | translate }}</th>
          <th>{{ 'apartment.unit' | translate }}</th>
          <th>{{ 'top-up.night' | translate }}</th>
          <th>{{ 'apartment.total' | translate }}</th>
<!--          <th>{{ 'top-up.discount' | translate }}</th>-->
<!--          <th>{{ 'top-up.total-by-discount' | translate }}</th>-->
<!--          <th>{{ 'top-up.total-after-discount' | translate }}</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of listPayment; let i = index">
          <td class="text-center" style="background-color: #F9FAFB">{{ i + 1 }}</td>
          <td>{{ item['name'] }}</td>
          <td>{{ item['price'] | number }}</td>
          <td>
            <div *ngIf="mode === itemMode.View">{{ item['night_count'] }}</div>
            <app-model name="night" *ngIf="mode !== itemMode.View" [value]="item['night_count']" (modelValueChange)="onChangeNight(item, $event)"
                       [controlConfig]="{type: 'number', maxlength: 3, max: 999, label: 'top-up.night'}"></app-model>
          </td>
          <td>{{ (item.total | number) || 0 }}</td>
<!--          <td>-->
<!--            <div *ngIf="mode === itemMode.View">-->
<!--              {{ item['discount'] | number }}{{ item['is_percent'] && item['discount'] ? '%' : '' }}</div>-->
<!--            <div class="d-flex gap-2" *ngIf="mode !== itemMode.View">-->
<!--              <app-model *ngIf="item['is_percent']" style="width: 200px" name="discount_percent" [value]="item.discount"-->
<!--                         (modelValueChange)="onChangeDiscount(item, $event)"-->
<!--                         [controlConfig]="{type: 'dropdown', label: 'top-up.discount', options: listDiscountPercent}"></app-model>-->
<!--              <app-model *ngIf="!item['is_percent']" style="width: 200px" name="discount" [value]="item.discount"-->
<!--                         [max]="item['is_percent'] ? 100 : (item.total || 99999999999999)"-->
<!--                         (modelValueChange)="onChangeDiscount(item, $event)" [controlConfig]="{type: 'number', label: 'top-up.discount'}"></app-model>-->
<!--              <app-model [value]="item['is_percent']" (modelValueChange)="onChangePercent(item, $event)" [controlConfig]="{type: 'checkbox', label: '%'}"></app-model>-->
<!--            </div>-->
<!--          </td>-->
<!--          <td>{{ item['discount_cost'] | number }}</td>-->
<!--          <td>{{ item['total_after_discount'] | number }}</td>-->
        </tr>
        </tbody>
      </nz-table>
    </div>
    <div class="mt-3">
<!--      <div class="fs-14 d-flex">-->
<!--        <div style="width: 200px">{{ 'payment.service' | translate }}</div>-->
<!--        <div style="width: 100px; text-align: right">{{ totalService ? (totalService | number) : 0 }}</div>-->
<!--      </div>-->
<!--      <div class="fs-14 d-flex">-->
<!--        <div style="width: 200px">{{ 'payment.discount' | translate }}</div>-->
<!--        <div style="width: 100px; text-align: right">{{ totalDiscount ? (totalDiscount | number) : 0 }}</div>-->
<!--      </div>-->
<!--      <div class="fs-14 d-flex">-->
<!--        <div style="width: 200px">{{ 'payment.after-discount' | translate }}</div>-->
<!--        <div style="width: 100px; text-align: right">{{ totalAfterDiscount ? (totalAfterDiscount | number) : 0 }}</div>-->
<!--      </div>-->
<!--      <div class="fs-14 d-flex">-->
<!--        <div style="width: 200px">{{ 'payment.vat' | translate: {percent: vat} }}</div>-->
<!--        <div style="width: 100px; text-align: right">{{ totalVAT ? (totalVAT | number) : 0 }}</div>-->
<!--      </div>-->
      <div class="fs-14 d-flex">
        <div style="width: 200px">{{ 'payment.total' | translate }}</div>
        <div style="width: 100px; text-align: right">{{ totalPayment ? (totalPayment | number) : 0 }}</div>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-3" style="border-radius: 4px" *ngIf="mode === itemMode.Add">
    <h4>{{ 'top-up.payment-info' | translate }}</h4>
    <div nz-row [nzGutter]="[16,16]">
      <div nz-col nzSpan="8">
        <app-control formControlName="payment_method" [options]="listPaymentMethod" [controlConfig]="{title: 'payment.type', type: 'dropdown', required: true, options: listPaymentMethod}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="paid_amount" [controlConfig]="{title: 'payment.paid', required: true, type: 'number'}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="payer" [controlConfig]="{title: 'billing.payer', required: true, type: 'text'}"></app-control>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-3" style="border-radius: 4px" *ngIf="mode !== itemMode.Add">
    <div class="w-100 d-flex justify-content-between">
      <h4>{{ 'top-up.payment-info' | translate }}</h4>
      <div>
        {{ 'payment.left' | translate }}: <span class="text-red">{{ (totalDue | number) || 0 }}</span>
<!--        <lucide-icon [name]="expandPayment ? 'chevron-up' : 'chevron-down'" (click)="expandPayment = !expandPayment" class="ms-2"></lucide-icon>-->
      </div>
    </div>
    <div *ngIf="expandPayment">
      <nz-table #table [nzData]="listBillingDetail" [nzScroll]="listBillingDetail.length > 5 ? {y: '10vh'} : {}" nzTableLayout="auto" [nzBordered]="true" nzSize="small"
                [nzShowPagination]="false" class="mt-3">
        <thead>
        <tr>
          <th class="text-center" style="width: 60px">{{ 'index' | translate }}</th>
          <th>{{ 'billing.code' | translate }}</th>
          <th>{{ 'billing.date' | translate }}</th>
          <th>{{ 'billing.payer' | translate }}</th>
          <th>{{ 'payment.type' | translate }}</th>
          <th>{{ 'billing.cost' | translate }}</th>
        </tr>
        </thead>
        <ng-container *ngIf="listBillingDetail.length">
          <tbody>
          <ng-container *ngFor="let item of listBillingDetail; let i = index">
            <tr *ngIf="item['deleted'] !== deleteStatus.YES">
              <td class="text-center">{{ i + 1 }}</td>
              <td>
                <a href="javascript:void(0)" class="textAsLink" (click)="viewDetailBill(item)">{{ item.ticket_no }}</a>
              </td>
              <td>{{ item.payment_date | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.payer }}</td>
              <td>{{ item.payment_method ? (item.payment_method | translate) : '' }}</td>
              <td>{{ item['total_received_amount'] | number }}</td>
            </tr>
          </ng-container>
          </tbody>
        </ng-container>
      </nz-table>

      <ng-container *ngIf="!listBillingDetail.length">
        <div style="width: 100%; text-align: center">
          <ord-grid-not-data></ord-grid-not-data>
        </div>
      </ng-container>
    </div>
  </div>
</form>

<div *nzModalTitle class="title-modal">{{ ('top-up.' + mode) |translate }}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" *ngIf="!data.is_auto_generated" (click)="editMode()">{{ 'edit' | translate }}</button>
    <button nz-button nzType="primary" (click)="print()">{{ 'button.print'| translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode !== itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>
    <button nz-button nzType="primary" [disabled]="listPayment.length > 1"
            (click)="onSave(true)">{{ 'save_and_booking' | translate }}</button>
  </ng-container>
</div>
